import React from 'react';
import { builder, Builder } from '@builder.io/react';
import Scroll from './Scroll';
import rachelle from '../assets/images/author.jpg';
import collage from '../assets/images/collage.jpg';
import book from '../assets/images/book.png';

// Initialize Builder SDK
builder.init('4142faaf0fd44218a63b748ff465adf0');

export default function VerticalFeature({
  bookImage,
  bookImageAlt,
  title,
  subtitle,
  text,
  buttonText,
  buttonLink,
}) {
  return (
    <section id="debut" className="projects-section bg-light">
      <div className="container">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            style={{ margin: 'auto' }}
            width="450px"
            className="img-fluid mb-3 mb-lg-0"
            src={bookImage}
            alt={bookImageAlt}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '40px',
            fontWeight: 800,
          }}
        >
          <a
            href={buttonLink}
            style={{
              border: 'solid 1px #d77743',
              padding: '10px 25px',
              borderRadius: '5px',
            }}
          >
            {buttonText}
          </a>
        </div>
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="w-[full] padding-right">
            <div className="featured-text text-center text-lg-left">
              <h4>{title}</h4>
              <p className="text-black-50 mb-0">
                <strong>{subtitle}</strong>
              </p>
              <br />
              <div
                className="text-black-50 mb-0"
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// Register the Hero component with Builder.io
Builder.registerComponent(VerticalFeature, {
  name: 'VerticalFeature',
  inputs: [
    { name: 'bookImage', type: 'file' },
    { name: 'bookImageAlt', type: 'text' },
    { name: 'buttonLink', type: 'text' },
    { name: 'buttonText', type: 'text' },
    { name: 'title', type: 'text' },
    { name: 'subtitle', type: 'text' },
    { name: 'text', type: 'richText' },
  ],
});
