import React from 'react';
import { builder, Builder } from '@builder.io/react';
import Scroll from './Scroll';
import rachelle from '../assets/images/author.jpg';

// Initialize Builder SDK
builder.init('4142faaf0fd44218a63b748ff465adf0');

export default function Hero({ title, text }) {
  return (
    <header>
      <section id="about" className="projects-section bg-gray">
        <div className="container">
          <div className="row align-items-center no-gutters mb-4 mb-lg-5">
            <div className="col-xl-4 col-lg-5">
              <img
                className="img-fluid bio"
                src={rachelle}
                alt="Rachelle Nelson"
              />
            </div>
            <div className="padding-left col-xl-8 col-lg-7">
              <div className="featured-text text-color text-center text-lg-left">
                <h4>{title || 'Rachelle Nelson'}</h4>

                <div dangerouslySetInnerHTML={{ __html: text }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
}

// Register the Hero component with Builder.io
Builder.registerComponent(Hero, {
  name: 'Hero',
  inputs: [
    { name: 'title', type: 'text' },
    { name: 'text', type: 'richText' },
  ],
});
