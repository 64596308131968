import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Contact from '../components/Contact';
import Header from '../components/Header';
import Hero from '../components/Hero';
import VerticalFeature from '../components/VerticalFeature';
import HorizontalFeature from '../components/HorizontalFeature';
import PeopleOfColor from '../components/PeopleOfColor';

import rachelle from '../assets/images/author.jpg';
import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';
import collage from '../assets/images/collage.jpg';
import book from '../assets/images/book.png';
import { graphql } from 'gatsby';
import { builder, BuilderComponent } from '@builder.io/react';

builder.init('4142faaf0fd44218a63b748ff465adf0');

const IndexPage = ({ data }) => {
  const models = data?.allBuilderModels;
  const page = models?.onePage?.content;
  return (
    <Layout>
      <Header />
      <BuilderComponent name="page" content={page} />
      <Contact />
      <Subscribe />
      <SocialLinks />
      <Footer />
    </Layout>
  );
};

export default IndexPage;

// pageQuery is a GraphQL query that
// fetches each page's content from Builder.
// Your content is rendered within the
// PageTemplate using BuilderComponent, provided by Builder's SDK.
export const pageQuery = graphql`
  query ($path: String!) {
    allBuilderModels {
      onePage(target: { urlPath: $path }) {
        content
      }
    }
  }
`;
