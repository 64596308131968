import React, { Component } from 'react';
import { builder, Builder } from '@builder.io/react';
import { StaticImage } from 'gatsby-plugin-image';
import collage from '../assets/images/collage.jpg';
import book from '../assets/images/pow.png';

builder.init('4142faaf0fd44218a63b748ff465adf0');

export default function HorizontalFeature({
  title,
  subtitle,
  aside,
  text,
  bookImage,
  bookImageAlt,
  buttonText,
  buttonLink,
}) {
  return (
    <section id="people-of-water" className="projects-section bg-light">
      <div className="container row-section">
        <div style={{ flex: 1 }}>
          <div className="row align-items-center no-gutters mb-4 mb-lg-5">
            <div className="w-[full] padding-right">
              <div className="featured-text text-center text-lg-left">
                <h3>{title}</h3>
                <p>{aside}</p>
                <p className="text-black-50 mb-0">
                  <strong>{subtitle}</strong>
                </p>
                <br />
                <div dangerouslySetInnerHTML={{ __html: text }}></div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{ margin: 'auto' }}
              width="450px"
              className="img-fluid mb-3 mb-lg-0"
              src={bookImage}
              alt={bookImageAlt}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '40px',
              fontWeight: 800,
            }}
          >
            <a
              href={buttonLink}
              style={{
                border: 'solid 1px #d77743',
                padding: '10px 25px',
                borderRadius: '5px',
              }}
            >
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

Builder.registerComponent(HorizontalFeature, {
  name: 'HorizontalFeature',
  inputs: [
    { name: 'bookImage', type: 'file' },
    { name: 'bookImageAlt', type: 'text' },
    { name: 'buttonLink', type: 'text' },
    { name: 'buttonText', type: 'text' },
    { name: 'title', type: 'text' },
    { name: 'aside', type: 'text' },
    { name: 'subtitle', type: 'text' },
    { name: 'text', type: 'richText' },
  ],
});
